<template>
  <div class="main-content">
    <b-card>
      <good-data-table 
        ref="exp_categories" 
        :button-label="$t('Add Category')"
        @add-new-info="New_Category"
        :columns="columns" :no-serial="true"
        :total-column="'response.responseData.total'" 
        :data-column="'response.responseData.data'"
        :api-endpoint="'/account/expenses-category'"
      />
    </b-card>

    <validation-observer ref="Create_Category">
      <b-modal hide-footer v-model="formModal" size="lg" :title="editmode?$t('Edit'):$t('Add')">
        <b-form @submit.prevent="Submit_Category">
          <b-row>
            <!-- Name Category -->
            <b-col md="12">
              <validation-provider
                name="Name category"
                :rules="{ required: true}"
                v-slot="validationContext"
              >
                <b-form-group :label="$t('Category Name')">
                  <b-form-input
                    :state="getValidationState(validationContext)"
                    aria-describedby="category-feedback"
                    label="name"
                    v-model="category.name"
                  ></b-form-input>
                  <b-form-invalid-feedback id="category-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Description Category -->
            <b-col md="12">
              <b-form-group :label="$t('Description')">
                <textarea
                  v-model="category.description"
                  rows="4"
                  name="Description"
                  id="Description"
                  class="form-control"
                  :placeholder="$t('Write a description')"
                ></textarea>
              </b-form-group>
            </b-col>
           
            <b-col md="12" class="mt-3">
              <b-button variant="primary" type="submit"  :disabled="SubmitProcessing"><i class="i-Yes me-2 font-weight-bold"></i> {{$t('submit')}}</b-button>
                <div v-once class="typo__p" v-if="SubmitProcessing">
                  <div class="spinner sm spinner-primary mt-3"></div>
                </div>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import { showDangerNotification, showSuccessNotification } from "@/@core/comp-functions/ui/app";
import { mapGetters } from "vuex";
import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
const apiUrl = `${jwtDefaultConfig.apiEndpoint}/account`;
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'

export default {
  metaInfo: {
    title: "Expense Category"
  },
  
  components: {
    GoodDataTable
  },

  data() {
    return {
      isLoading: true,
      SubmitProcessing:false,
      formModal:false,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "id",
          type: "desc"
        },
        page: 1,
        perPage: 10
      },
      selectedIds: [],
      totalRows: "",
      search: "",
      categories: [],
      editmode: false,
      limit: "10",
      category: {
        id: "",
        name: "",
        description: ""
      }
    };
  },

  computed: {
    ...mapGetters(["currentUserPermissions"]),
    columns() {
      return [
        {
          label: this.$t("Category Name"),
          field: "name",
        },
        {
          label: this.$t("Description"),
          field: "description",
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.Edit_Category(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('delete'),
              text: 'Delete',
              action: props => {
                this.$swal({
                  title: this.$t('You want to delete?'),
                  iconHtml: this.$helpers.swalIcon('alert.png'),
                  showCancelButton: true,
                  confirmButtonText: this.$t('Yes'),
                  cancelButtonText: this.$t('No'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                    icon: 'border-0',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.value) {
                    this.Delete_Category(props)
                  }
                })
              },
            },
          ],
        },
      ];
    }
  },

  methods: {
    //------------- Submit Validation Create & Edit Category
    Submit_Category() {
      this.$refs.Create_Category.validate().then(success => {
        if (!success) {
          showDangerNotification(this, "Something went wrong")
        } else {
          if (!this.editmode) {
            this.Create_Category();
          } else {
            this.Update_Category();
          }
        }
      });
    },

    //------ Event Validation State
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    //--------------------------Show Modal (new Category) ----------------\\
    New_Category() {
      this.reset_Form();
      this.editmode = false;
      this.formModal = true;
    },

    //-------------------------- Show Modal (Edit Category) ----------------\\
    Edit_Category(cat) {
      this.reset_Form();
      this.category = cat;
      this.editmode = true;
      this.formModal = true;
    },

    //--------------------------- reset Form ----------------\\
    reset_Form() {
      this.category = {
        id: "",
        name: "",
        description: ""
      };
    },

    //----------------------------------Create new Category ----------------\\
    Create_Category() {
      this.SubmitProcessing = true;
      axios
        .post(apiUrl+"/expenses-category", {
          name: this.category.name,
          description: this.category.description
        })
        .then(response => {
          this.$refs.exp_categories.loadItems();
          showSuccessNotification(this, "Category created successfully!");
          this.SubmitProcessing = false;
          this.formModal = false;
        })
        .catch(error => {
          showDangerNotification(this, "Something went wrong")
          this.SubmitProcessing = false;
          this.formModal = false;
        });
    },

    //---------------------------------- Update Category ----------------\\
    Update_Category() {
      this.SubmitProcessing = true;
      axios
        .put(apiUrl+"/expenses-category/" + this.category.id, {
          name: this.category.name,
          description: this.category.description
        })
        .then(response => {
          this.$refs.exp_categories.loadItems();
          showSuccessNotification(this, "Category updated successfully!");
          this.SubmitProcessing = false;
          this.formModal = false;
        })
        .catch(error => {
          showDangerNotification(this, "Something went wrong")
          this.SubmitProcessing = false;
          this.formModal = false;
        });
    },

    //--------------------------- Delete Category----------------\\
    Delete_Category(props) {
      axios
        .delete(apiUrl+"/expenses-category/" + props.id)
        .then(() => {
          showSuccessNotification(this, "Categoty Deleted");
          this.$refs.exp_categories.loadItems();
        });
    },

  
  }, //end Methods

  //----------------------------- Created function-------------------

  mounted: function() {
    this.$refs.exp_categories.loadItems();  
  }
};
</script>
